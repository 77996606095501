export const christmas = [
  { name: 'Christmas tree', text: '🎄' },
  { name: 'Baby angel', text: '👼' },
  { name: 'Wrapped gift', text: '🎁' },
  { name: 'Glowing star', text: '🌟' },
  { name: 'Socks', text: '🧦' }
]

export const mrsClauses = [
  { name: 'Mother Christmas', text: '🤶' },
  { name: 'Mother Christmas with light skin tone', text: '🤶🏻' },
  { name: 'Mother Christmas with medium light skin tone', text: '🤶🏼' },
  { name: 'Mother Christmas with medium skin tone', text: '🤶🏽' },
  { name: 'Mother Christmas with medium dark skin tone', text: '🤶🏾' },
  { name: 'Mother Christmas with dark skin tone', text: '🤶🏿' }
]

export const santas = [
  { name: 'Santa Claus', text: '🎅' },
  { name: 'Santa with light skin tone', text: '🎅🏻' },
  { name: 'Santa with medium light skin tone', text: '🎅🏼' },
  { name: 'Santa with medium skin tone', text: '🎅🏽' },
  { name: 'Santa with medium dark skin tone', text: '🎅🏾' },
  { name: 'Santa with dark skin tone', text: '🎅🏿' }
]

export const other = [
  { name: 'Mahjong Tile Winter', text: '🀩' }
]

export const snowflakes = [
  { name: 'Snowflake emoji', text: '❄️' },
  { name: 'Tight trifoliate snowflake', text: '❅' },
  { name: 'Heavy chevron snowflake ', text: '❆' },
  { name: 'Heavy teardrop-spoked pinwheel asterisk', text: '❃' },
  { name: 'Sparkle', text: '❇' },
  { name: 'Heavy sparkle', text: '❈' },
  { name: 'Balloon-spoked asterisk', text: '❉' },
  { name: 'Eight teardrop-spoked propeller asterisk', text: '❊' },
  { name: 'Heavy eight teardrop-spoked propeller asterisk ', text: '❋' }
]

export const winterEmojis = [
  { name: 'Snowflake emoji', text: '❄️' },
  { name: 'Snowman emoji ', text: '⛄' },
  { name: 'Snowman with snowflakes', text: '☃️' },
  { name: 'Snow capped mountain', text: '🏔️' },
  { name: 'Snow cloud', text: '🌨' },
  { name: 'Turkey', text: '🦃' },
  { name: 'Dear', text: '🦌' },
  { name: 'Evergreen tree', text: '🌲' },
  { name: 'Chestnut', text: '🌰' },
  { name: 'Skis', text: '🎿' },
  { name: 'Snowboarder', text: '🏂' },
  { name: 'Skier', text: '⛷️' },
  { name: 'Cold face', text: '🥶' },
  { name: 'Ice skate', text: '⛸️' }
]

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Meta' },
  { name: 'Symbols' },
  { name: 'Emoji' },
  { name: 'Copy and paste' },
  { name: 'Memes' },
  { name: 'Dingbats' },
  { name: 'Snowflakes' }
]

const stars = ['❅', '❆']
const dots = ['.', '*', '·', '•']

export const shapes = {
  stars,
  dots
}

export const density = 10
export const height = 20
export const width = 40
