import { shapes } from './constants'

export const generateSnowstorm = (density: number, height: number, width: number): string => {
  let str = ''
  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      const char = starChance(density) ? random('stars') : spaceChance()
      str += char
    }
    str += '\r\n'
  }
  return str
}

const starChance = (density: number): boolean => {
  return (Math.floor(Math.random() * 100) + 1) < density
}

const spaceChance = (): string => {
  return (Math.floor(Math.random() * 100) + 1) < 25 ? random('dots') : '\u3000'
}

const random = (arrName: 'stars' | 'dots'): string => {
  return shapes[arrName][Math.floor(Math.random() * shapes[arrName].length)]
}
