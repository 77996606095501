import React from 'react'

import { Card } from '../../../components/Card'
import { TextWithImage } from '../../../components/TextWithImage'
import { Tile } from '../../../components/Tile'
import { Title } from '../../../components/Title'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { Canvas } from '../components/Canvas'

import { CopyAndPaste } from '..'

import { christmas, density, height, mrsClauses, santas, snowflakes, tags, width, winterEmojis } from './constants'

import { generateSnowstorm } from './functions'

import majongImg from './assets/majong.webp'
import SnowflakeImg from './assets/snowflakes.svg'

import styles from './styles.module.scss'
import { starSymbolsTool } from '../../tools'

const tileText = `
  Mahjong is an ancient Chinese game played with tiles.
  Just like playing cards, chess pieces, and dominos,
  Mahjong tiles were added to Unicode to help people write about the game.
  One of those tiles has a snowflake on it.
`
const generator = (): string => generateSnowstorm(density, height, width)

const SnowflakeSymbols: React.FC = () => {
  return (
    <CopyAndPaste tags={tags} moreTools={[starSymbolsTool]} subtitleClassName={styles.subtitle} titleClassName={styles.title}>
      <TextWithImage image={<SnowflakeImg />}>
        <>
          <p>
            Snowflakes were added to Unicode as part of a set of Dingbat symbols based on the designs of Herman Zapf.
            If you were around in the early days of the internet, you’ll remember these as Zapf Dingbats, Webdings, or Wingdings.
          </p>
          <p>
            Snowflakes have been available as text symbols on computers since 1993, so you can be sure they’ll render correctly anywhere online.
            Use this page to copy and paste snowflakes, winter emojis, and snowflake-like symbols.
          </p>
        </>
      </TextWithImage>

      <Title className={styles['secondary-title']} size={3}>Snowflakes, asterisks, and similar shapes</Title>
      <VariantsContainer className={styles['variants-container']}>
        {snowflakes.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Tile className={styles.tile} title='Mahjong winter tile' text={tileText} image={<img src={majongImg} />} />
      <Canvas title='Snowstorm generator' subtitle='Click the button below to generate a Unicode blizzard.' generator={generator} buttonText='Randomize snow' />
      <Title className={styles['secondary-title']} size={3}>Winter emojis</Title>
      <VariantsContainer className={styles['variants-container']}>
        {winterEmojis.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <Title className={styles['secondary-title']} size={3}>Christmas emojis</Title>
      <small className={styles['secondary-subtitle']}>Father Christmas/Santa emojis</small>
      <VariantsContainer className={styles['variants-container']}>
        {santas.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <small className={styles['secondary-subtitle']}>Mother Christmas/Mrs. Claus emojis</small>
      <VariantsContainer className={styles['variants-container']}>
        {mrsClauses.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
      <VariantsContainer className={styles['variants-container']}>
        {christmas.map(variant => <Card className={styles.card} key={variant.name} title={variant.name} text={variant.text} textFontSize={32} textAlignCenter />)}
      </VariantsContainer>
    </CopyAndPaste>
  )
}

export default SnowflakeSymbols
